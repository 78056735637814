import moment from 'moment';
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function View() {
    const { state } = useLocation();
    return (
        <>
            <div className="container-fluid mt-4">
                <div className="p-4 ">
                    <h1 className="text-center mb-4">Contact us Form Detail</h1>
                    <div className="row mb-3">
                        <div className="col-md-6 mt-5">
                            <h4 className='title-heading mb-5'>{state.fullName}</h4>
                            <p className='mb-3'><strong>Date:</strong> {moment(state.createdAt).format("MMMM D, YYYY")}</p>
                            <p className='mb-3'><strong>Email:</strong> <Link to={`mailto:${state.email}`} > {state.email}</Link></p>
                            <p className='mb-3'><strong>Subject:</strong> {state.subject}</p>
                            <p className='mb-3'><strong>Message:</strong> {state.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default View