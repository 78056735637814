import React, { useEffect, useState } from 'react'
import SimpleBackdrop from '../../../componets/SimpleBackdrop';
import instance from '../../../services/apiServices/Api';
import { errorMessage, successMessage } from '../../../helpers/Message';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from '../../../componets/ConfirmDeleteModal';
import NoRecordFound from '../../../componets/NoRecordFound';

function Career() {

    const [careerData, setCareerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [careerDeleteID, setCareerDeleteId] = useState(null);

    const getCareerList = () => {
        setLoading(true);
        instance.get(`/career`)
            .then((res) => {
                setLoading(false);
                setCareerData(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response.data.error);
            });
    }

    const deleteCareerById = () => {
        if (careerDeleteID) {
            setLoading(true);
            instance.delete(`/delete-career/${careerDeleteID}`)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    getCareerList();
                    successMessage(
                        "Success!",
                        "Career Deleted Successfuly"
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    errorMessage("Error", err.response.data.error);
                });
        }
    }

    useEffect(() => {
        getCareerList();
    }, [])

    return (
        <>
            {loading && <SimpleBackdrop />}
            <div className="mt-4 container-fluid">
                <div className="align-items-center">
                    <h1 className="">Career Form List</h1>
                </div>
                {careerData.length > 0 ?
                    <table className="table table-striped mt-5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Designation</th>
                                <th>Resume</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {careerData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.firstName} {item.lastName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.designation}</td>
                                    <td><Link to={`${process.env.REACT_APP_BACKEND_BASE_IMAGE_URL}/${item.fileUrl}`} target='_blank'>Download</Link></td>
                                    <td>
                                        <Link className="btn btn-primary btn-sm me-2" to='view' state={item}>View</Link>
                                        <button className="btn btn-danger btn-sm" onClick={() => {setCareerDeleteId(item.id);setShowModal(true)}}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    :
                    !loading ? <NoRecordFound title='No Submissions Yet' text="It looks like we haven't received any form submissions yet. If you have recently submitted a form, please allow some time for it to be processed." />
                        : <h1 className='title-heading mt-5 text-center'> Loading Data ...... </h1>
                }
            </div>

            {showModal && <ConfirmDeleteModal title="Career Data" handleDelete={deleteCareerById} hideModal={() => setShowModal(false)} />}
        </>
    )
}

export default Career