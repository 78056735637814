import Swal from "sweetalert2"

export const errorMessage = (title, text) => {
    return (
        Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            confirmButtonText: 'Okay'
        })
    )
}

export const successMessage = (title, text) => {
    return (
        Swal.fire({
            title: title,
            text: text,
            icon: "success",
            confirmButtonText: 'Okay'
        })
    )
}

export const isLoggedInMsg = (text) => {
    return (
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: text,
            showConfirmButton: false,
            timer: 1500
        })
    )
}