import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { errorMessage, successMessage } from '../../../helpers/Message';
import instance from '../../../services/apiServices/Api';
import SimpleBackdrop from '../../../componets/SimpleBackdrop';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import placeholderImage from '../../../assets/home/Placeholder-image.webp'
import { useNavigate, useParams } from 'react-router-dom';



function CreateAndEditForm() {

  const { id } = useParams();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null)
  const [previewImage, setPreviewImage] = useState(null)

  const handleFileChange = (e) => {
    setImage(e.target.files[0])
    formik.setFieldValue('imageFileName', e.target.files[0].name)
    setPreviewImage(URL.createObjectURL(e.target.files[0]))
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      feedbackText: "",
      image: null,
      imageFileName: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Full name is required"),
      feedbackText: Yup.string().required("Feedback text is required"),
      imageFileName: Yup.string().required("Image is required"),
    }),

    onSubmit: (values) => {
      const formData = new FormData()
      formData.append("fullName", values.fullName);
      formData.append("feedbackText", values.feedbackText);
      formData.append("image", image);
      setLoading(true)
      if (id) {
        instance.put(`/update-feedback/${id}`, formData)
          .then((res) => {
            setLoading(false)
            successMessage('Success', "Feedback updated Successfuly").then(() => navigate('/dashboard/feedback'))
          })
          .catch((err) => {
            setLoading(false);
            errorMessage("Error", err.response?.message);
          });
      } else {
        instance.post('/feedback', formData)
          .then((res) => {
            setLoading(false)
            successMessage('Success', "Feedback created Successfuly").then(() => navigate('/dashboard/feedback'))
          })
          .catch((err) => {
            setLoading(false);
            errorMessage("Error", err.response?.message);
          });
      }

    },
  });

  const getFeedbackById = (id) => {
    setLoading(true)
    instance.get(`/feedback/${id}`)
      .then((res) => {
        setLoading(false);
        const data = res.data.data
        formik.setFieldValue('fullName', data.fullName);
        formik.setFieldValue('feedbackText', data.feedbackText);
        formik.setFieldValue('image', data.image);
        formik.setFieldValue('imageFileName', "Feedback Profile Image");
        setPreviewImage(data.image)
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response.data.message);
      });
  }

  useEffect(() => {
    if (id) {
      getFeedbackById(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      {loading && <SimpleBackdrop />}
      <div className="container-fluid mt-4 feedback-form">
        <div className="align-items-center d-flex justify-content-between mb-4">
          <h1 className='mb-5'>{id ? "Edit Feedback" : "Create Feedback"}</h1>
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="auth-outer"
          action="#"
        >
          <div className="form-row">
            <div className="form-group col-md-5">
              <TextField
                id="outlined-multiline-flexible"
                label="Full Name"
                className='input-field'
                multiline
                error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                {...formik.getFieldProps("fullName")}
              />
              {formik.errors.fullName && formik.touched.fullName ? (
                <div className="validation-error">
                  {formik.errors.fullName}
                </div>
              ) : null}
            </div>
            <div className="form-group col-md-5">
              <TextField
                id="outlined-multiline-flexible"
                label="Feedback Text"
                className='input-field'
                multiline
                error={formik.touched.feedbackText && Boolean(formik.errors.feedbackText)}
                {...formik.getFieldProps("feedbackText")}
              />
              {formik.errors.feedbackText && formik.touched.feedbackText ? (
                <div className="validation-error">
                  {formik.errors.feedbackText}
                </div>
              ) : null}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5 ">
              <div className='d-flex justify-content-between'>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Upload Image"
                  className='input-file-field'
                  multiline
                  error={formik.touched.imageFileName && Boolean(formik.errors.imageFileName)}
                  disabled
                  {...formik.getFieldProps("imageFileName")}
                />
                <Button
                  variant="contained"
                  component="label"
                  className='file-button'
                >
                  {'Choose File'}
                  <input
                    type="file"
                    hidden
                    id="resume"
                    name="resume"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
              {formik.errors.imageFileName && formik.touched.imageFileName ? (
                <div className="validation-error">
                  {formik.errors.imageFileName}
                </div>
              ) : null}
            </div>
            <div className="form-group col-md-5 d-flex justify-content-between">
              <img src={previewImage ? previewImage : placeholderImage} alt="feedback-profile" className='preview-img' />
            </div>
          </div>
          <div className="align-items-center d-flex justify-content-center mt-5">
            <button type="submit" className="primary-btn btn-large submit-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  )
}

export default CreateAndEditForm