import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import placeholderImage from '../../../assets/home/Placeholder-image.webp'

function View() {
    const { state } = useLocation();
    const [imageLoaded, setImageLoaded] = useState(false)

    return (
        <>
            <div className="container-fluid mt-4 detail-outer">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <h1 className="mb-4">Feedback Detail</h1>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mt-4">
                    <div className="col-md-12">
                        <div className="profile-info">
                            <img src={state.image} alt="Profile" onLoad={()=>setImageLoaded(true)} className="profile-image" style={{ display: imageLoaded ? 'block' : 'none' }} />
                            {
                                !imageLoaded && <img src={placeholderImage} className='profile-image' alt='profile' />
                            }
                            <h4 className="mb-2">{state.fullName}</h4>
                        </div>
                        <div className="feedback-text">
                            <p>{state.feedbackText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default View