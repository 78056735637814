import React, { useEffect, useState } from 'react'
import CountUp from 'react-countup';
import { errorMessage } from '../../helpers/Message';
import instance from '../../services/apiServices/Api';
import SimpleBackdrop from '../../componets/SimpleBackdrop';


function DashboardHome() {
    const [dashboardCounts, setDashboardCounts] = useState({})
    const [loading, setLoading] = useState(false);

    const getDashboardCount = () => {
        instance.get(`/dashboard-count`)
            .then((res) => {
                setLoading(false);
                setDashboardCounts(res.data.data.totalCounts);
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response.data.error);
            });
    }

    useEffect(() => {
        getDashboardCount();
    }, [])
    return (
        <>
            {loading && <SimpleBackdrop />}
            <div className="container-fluid dashboard-home">
                <h1 className="mt-4 mb-4">Dashboard</h1>

                <div className="row">
                    {/* Total Contact Us Forms */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-title">Total Contact Us Forms</h5>
                                <CountUp duration={3} className="card-text display-4" end={dashboardCounts.contactUsCount} />
                            </div>
                        </div>
                    </div>

                    {/* Total Careers */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-title">Total Careers Forms</h5>
                                <CountUp duration={3} className="card-text display-4" end={dashboardCounts.careerCount} />
                            </div>
                        </div>
                    </div>

                    {/* Total Blog Posts */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-title">Total Blog Posts</h5>
                                <CountUp duration={3} className="card-text display-4" end={dashboardCounts.blogsCount} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {/* Total FeedBack Forms */}
                    <div className="col-lg-4 col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-title">Total  Feedback</h5>
                                <CountUp duration={3} className="card-text display-4" end={dashboardCounts.feedbackCount} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DashboardHome