import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import facebookSvg from "../assets/home/facebook-fill.svg";
import instagramSvg from "../assets/home/instagram-fill.svg";
import twitterSvg from "../assets/home/x-fill.svg";
import linkedinSvg from "../assets/home/linkedin-fill.svg";
import youtubeSvg from "../assets/home/youtube-fill.svg";

function SidebarSocialLinks() {
  const [hideSidebar, setHideSidebar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector(".footer-section");
      const footerTop = footer.getBoundingClientRect().top;

      if (footerTop < 575) {
        setHideSidebar(true);
      } else {
        setHideSidebar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <section
      className={`sidebar-social-section ${hideSidebar ? "d-none" : ""}`}
    >
      <ul>
        <li className="">
          <Link to="https://www.facebook.com/usravens" target="_blank">
            <img src={facebookSvg} alt="facebook svg" width='24' height='24' />
          </Link>
        </li>
        <li className="">
          <Link to="https://www.instagram.com/usravens/" target="_blank">
            <img src={instagramSvg} alt="instagram svg" width='24' height='24' />
          </Link>
        </li>
        <li className="">
          <Link to="https://twitter.com/USRavens1" target="_blank">
            <img src={twitterSvg} alt="twitter" width='24' height='24' />
          </Link>
        </li>
        <li className="">
          <Link to="https://www.linkedin.com/company/usravens/" target="_blank">
            <img src={linkedinSvg} alt="linkedin svg" width='24' height='24' />
          </Link>
        </li>
        <li className="">
          <Link
            to="https://www.youtube.com/channel/UC_-4o4vJCnh8f1cTnT5v-zw"
            target="_blank"
          >
            <img src={youtubeSvg} alt="youtube" width='24' height='24' />
          </Link>
        </li>
      </ul>
    </section>
  );
}

export default SidebarSocialLinks;
