import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { errorMessage, successMessage } from '../../helpers/Message';
import instance from '../../services/apiServices/Api';
import SimpleBackdrop from '../../componets/SimpleBackdrop';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import placeholderImage from '../../assets/home/Placeholder-image.webp'
import { useNavigate } from 'react-router-dom';
import Storage from '../../services/local_storage/localStorage';


function ProfileUpdate() {
    const userData = Storage.getUserData();
    const id = userData.id;
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null)
    const [previewImage, setPreviewImage] = useState(null)

    const handleFileChange = (e) => {
        setImage(e.target.files[0])
        formik.setFieldValue('imageFileName', e.target.files[0].name)
        setPreviewImage(URL.createObjectURL(e.target.files[0]))
    };

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            profileImage: null,
            imageFileName: '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("First name is required"),
            lastName: Yup.string().required("Last name is required"),
            email: Yup.string().required("Email is required").email("Invalid Email"),
            imageFileName: Yup.string().required("Image is required"),
        }),

        onSubmit: (values) => {
            const formData = new FormData()
            formData.append("firstName", values.firstName);
            formData.append("lastName", values.lastName);
            formData.append("email", values.email);
            formData.append("profileImage", image);
            setLoading(true)
            if (id) {
                instance.put(`/update-profile/${id}`, formData)
                    .then((res) => {
                        setLoading(false)
                        Storage.SetUserData(res.data.data);
                        successMessage('Success', "Profile updated Successfuly").then(() => navigate('/dashboard'))
                    })
                    .catch((err) => {
                        setLoading(false);
                        errorMessage("Error", err.response?.message);
                    });
            } else {
                errorMessage("Error", "Please check your details")
            }
        },
    });

    const getProfileData = (id) => {
        setLoading(true)
        instance.get(`/profile/${id}`)
            .then((res) => {
                setLoading(false);
                const data = res.data.data
                formik.setFieldValue('firstName', data.firstName);
                formik.setFieldValue('lastName', data.lastName);
                formik.setFieldValue('email', data.email);
                formik.setFieldValue('profileImage', data.profileImage);
                formik.setFieldValue('imageFileName', "Profile Image");
                setPreviewImage(data.profileImage)
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response.data.message);
            });
    }

    useEffect(() => {
        if (id) {
            getProfileData(id);
        }

        // eslint-disable-next-line
    }, [id])

    return (
        <>
            {loading && <SimpleBackdrop />}
            <div className="container-fluid mt-4">
                <div className="align-items-center">
                    <h1 className="">Profile</h1>
                </div>
                <div className="mt-5">
                    <form
                        onSubmit={formik.handleSubmit}
                        className="auth-outer"
                        action="#"
                    >
                        <div className="form-row">
                            <div className="form-group col-md-5">
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="First Name"
                                    className='input-field'
                                    multiline
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    {...formik.getFieldProps("firstName")}
                                />
                                {formik.errors.firstName && formik.touched.firstName ? (
                                    <div className="validation-error">
                                        {formik.errors.firstName}
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group col-md-5">
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Last Name"
                                    className='input-field'
                                    multiline
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    {...formik.getFieldProps("lastName")}
                                />
                                {formik.errors.lastName && formik.touched.lastName ? (
                                    <div className="validation-error">
                                        {formik.errors.lastName}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-5">
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="Email"
                                    className='input-field'
                                    multiline
                                    type='email'
                                    disabled
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.errors.email && formik.touched.email ? (
                                    <div className="validation-error">
                                        {formik.errors.email}
                                    </div>
                                ) : null}
                            </div>
                            <div className="form-group col-md-5 ">
                                <div className='d-flex justify-content-between'>
                                    <TextField
                                        id="outlined-multiline-flexible"
                                        label="Upload Image"
                                        className='input-file-field'
                                        multiline
                                        error={formik.touched.imageFileName && Boolean(formik.errors.imageFileName)}
                                        disabled
                                        {...formik.getFieldProps("imageFileName")}
                                    />
                                    <Button
                                        variant="contained"
                                        component="label"
                                        className='file-button'
                                    >
                                        {'Choose File'}
                                        <input
                                            type="file"
                                            hidden
                                            id="resume"
                                            name="resume"
                                            onChange={handleFileChange}
                                        />
                                    </Button>
                                </div>
                                {formik.errors.imageFileName && formik.touched.imageFileName ? (
                                    <div className="validation-error">
                                        {formik.errors.imageFileName}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className="align-items-center d-flex justify-content-start mt-5">
                            <img src={previewImage ?? placeholderImage} alt="profile" className='preview-img' />
                        </div>
                        <div className="align-items-center d-flex justify-content-center mt-5">
                            <button type="submit" className="primary-btn btn-large submit-btn">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ProfileUpdate