import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const BlogEditor = ({ data, handleContent }) => {
  const [content, setContent] = useState(data);
  const [isHtmlEditor, setIsHtmlEditor] = useState(false);

  const handleEditorChange = (content, editor) => {
    setContent(content); // This should update the content as is
    handleContent(content); // Pass the content to the parent component
  };

  const handleHtmlChange = (event) => {
    const value = event.target.value;
    setContent(value); // This updates the HTML directly
    handleContent(value);
  };

  const toggleEditor = () => {
    setIsHtmlEditor(!isHtmlEditor);
  };

  useEffect(() => {
    if (data) {
      setContent(data); // Ensure the content state matches the data prop
    }
  }, [data]);

  return (
    <div className="w-100">
      <h1>Editor</h1>
      <button onClick={toggleEditor} type="button">
        Switch to {isHtmlEditor ? "Text Editor" : "HTML Editor"}
      </button>
      {isHtmlEditor ? (
        <textarea
          style={{
            width: "100%",
            height: "300px",
            border: "1px solid #ccc",
            padding: "10px",
            marginTop: "20px",
            fontFamily: "monospace",
          }}
          value={content}
          onChange={handleHtmlChange}
        />
      ) : (
        <Editor
          apiKey="lk8qp6eek0oipuobawnpjejcn61a5hy5t1flc2zbwv9eb1x3"
          value={content}
          init={{
            height: 300,
            menubar: true, // Turn this to true if you want the menu bar for more features
            plugins: [
              "advlist autolink lists link image charmap preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
              "image"
            ],
            toolbar:
              "undo redo | formatselect | bold italic underline | " +
              "alignleft aligncenter alignright alignjustify | " +
              "bullist numlist outdent indent | removeformat | help",
            block_formats:
              "Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6", // Ensure correct formatting
            branding: false,
            image_title: true, // Allow image title
            automatic_uploads: true, // Enable automatic uploads
            file_picker_types: 'image', // Specify file types
            file_picker_callback: (cb, value, meta) => {
              const input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.onchange = function() {
                const file = this.files[0];
                const reader = new FileReader();
                reader.onload = function() {
                  const id = 'blobid' + (new Date()).getTime();
                  const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                  const base64 = reader.result.split(',')[1];
                  const blobInfo = blobCache.create(id, file, base64);
                  blobCache.add(blobInfo);
                  cb(blobInfo.blobUri(), { title: file.name });
                };
                reader.readAsDataURL(file);
              };
              input.click();
            }
          }}
          onEditorChange={handleEditorChange}
        />
      )}
      <div className="mt-5">
        <h2>Rendered HTML</h2>
      </div>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          marginTop: "20px",
          height: "300px",
          backgroundColor: "#f9f9f9",
          overflow: "scroll",
        }}
        dangerouslySetInnerHTML={{ __html: content }} // This ensures the content is rendered correctly
      />
    </div>
  );
};

export default BlogEditor;
