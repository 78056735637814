import React from 'react'
import UsravensLogo from '../assets/home/usravens-logo.webp'

function FallBack() {
    return (
        <div className='align-items-center d-flex justify-content-center' style={{width:"100%",height:"100vh"}}>
            <img className='fallback' src={UsravensLogo} alt='error' width='270px' height='auto' />
        </div>
    )
}

export default FallBack