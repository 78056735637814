import React from 'react'
// import { useNavigate } from 'react-router-dom';
import Img404 from '../assets/page not found/404.png'

function PageNotFound() {
  // const navigate = useNavigate()
  return (
    <section className="page-not-found">
      <div className=" position-relative w-100">
        <img className='w-100 flyy' src={Img404} alt="" />
      </div>
    </section>
  )
}

export default PageNotFound