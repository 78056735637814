import axios from "axios"
import { errorMessage } from '../../helpers/Message'
import Storage from '../local_storage/localStorage';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL
});

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  let token = Storage.getToken();

  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }
  // Do something before request is sent
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data

  return response;
}, function (error) {
  if (error?.response?.status === 401) {
    console.log("Error ", error);
    errorMessage('Error', error.response.data.message).then(() => {
    })
  }
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default instance;