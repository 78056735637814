import React, { useEffect, useState } from 'react'
import SimpleBackdrop from '../../../componets/SimpleBackdrop';
import instance from '../../../services/apiServices/Api';
import { errorMessage, successMessage } from '../../../helpers/Message';
import NoRecordFound from '../../../componets/NoRecordFound';
import ConfirmDeleteModal from '../../../componets/ConfirmDeleteModal';
import { Link } from 'react-router-dom';
import moment from 'moment';

function ContactUs() {
    const [contactsData, setContactsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [deleteID, setDeleteId] = useState(null);


    const getContactsList = () => {
        instance.get(`/contacts`)
            .then((res) => {
                setLoading(false);
                setContactsData(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response.data.error);
            });
    }
    const deleteCareerById = () => {
        if (deleteID) {
            setLoading(true);
            instance.delete(`/delete-contact/${deleteID}`)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    getContactsList();
                    successMessage(
                        "Success!",
                        "Contact Deleted Successfuly"
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    errorMessage("Error", err.response.data.error);
                });
        }
    }

    useEffect(() => {
        getContactsList();
    }, [])

    return (
        <>
            {loading && <SimpleBackdrop />}
            <div className="container-fluid mt-4">
                <div className="align-items-center d-flex justify-content-between mb-4">
                    <h1 className="">Contact us Form List</h1>
                </div>
                {contactsData.length > 0 ?

                    <table className="table table-striped mt-5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Subject</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contactsData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.fullName}</td>
                                    <td>{item.email}</td>
                                    <td>{item.subject}</td>
                                    <td>{moment(item.createdAt).format("MMMM D, YYYY")}</td>
                                    <td>
                                        <Link className="btn btn-primary btn-sm me-2" to='view' state={item}>View</Link>
                                        <button className="btn btn-danger btn-sm" onClick={() => {setDeleteId(item.id);setShowModal(true)}}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                    :
                    !loading ? <NoRecordFound title='No Submissions Yet' text="It looks like we haven't received any form submissions yet. If you have recently submitted a form, please allow some time for it to be processed." />
                        : <h1 className='title-heading mt-5 text-center'> Loading Data ...... </h1>
                }
            </div>
            {showModal && <ConfirmDeleteModal title="Contact Data" handleDelete={deleteCareerById} hideModal={() => setShowModal(false)} />}
        </>
    )
}

export default ContactUs