import React from 'react'
import { Navigate } from 'react-router-dom'

import Storage from '../services/local_storage/localStorage';

function ProtectedRoutes({ children }) {
    const isAuth = Storage.getToken("token") ? true : false

    return isAuth ? children : <Navigate to="/admin-login" />;

}

export default ProtectedRoutes