import React, { useEffect, useState } from 'react';
import SimpleBackdrop from '../../../componets/SimpleBackdrop';
import instance from '../../../services/apiServices/Api';
import { errorMessage, successMessage } from '../../../helpers/Message';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ConfirmDeleteModal from '../../../componets/ConfirmDeleteModal';
import NoRecordFound from '../../../componets/NoRecordFound';
import { Pagination } from '@mui/material';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Blogs() {

    const [blogsData, setBlogsData] = useState([]);
    const [authors, setAuthors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [blogsDeleteID, setBlogsDeleteId] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const query = useQuery();
    const navigate = useNavigate();
    const page = parseInt(query.get('page')) || 1;

    const getBlogsList = (page) => {
        setLoading(true);
        instance.get('/blogs', {
            params: {
                page: page,
                limit: 10
            }
        })
            .then((res) => {
                setLoading(false);
                setBlogsData(res.data.blogs);
                setTotalPages(res.data.totalPages);
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response.data.error);
            });
    }
    const getAuthorList = () => {
        setLoading(true);
        instance
          .get(`/author`, {
            params: {
              page: 1,
              limit: 100,
            },
          })
          .then((res) => {
            setLoading(false);
            setAuthors(res.data.data);
          })
          .catch((err) => {
            setLoading(false);
            errorMessage("Error", err.response?.message);
          });
      };

    const deleteBlogsById = () => {
        if (blogsDeleteID) {
            setLoading(true);
            instance.delete(`/delete-blog/${blogsDeleteID}`)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    getBlogsList();
                    successMessage(
                        "Success!",
                        "Blogs Deleted Successfuly"
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    errorMessage("Error", err.response.data.error);
                });
        }
    }

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        navigate(`?page=${value}`);
    };

    useEffect(() => {
        setCurrentPage(page);
        getBlogsList(page);
        getAuthorList();

    }, [page]);

    return (
        <>
            {loading && <SimpleBackdrop />}
            <div className="container-fluid mt-4">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h1 className="">Blogs Form List</h1>
                    <Link className='btn btn-primary' to={'create'}>Create</Link>
                </div>
                {blogsData.length > 0 ?
                    <>
                        <table className="table table-striped mt-5">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Title</th>
                                    <th>Slug</th>
                                    <th>Summary</th>
                                    <th>Author</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {blogsData.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{item.slug}</td>
                                        <td>{item.summary}</td>
                                        <td>{authors.find((items)=>items.id==item.author)?.fullName}</td>
                                        <td>
                                            <Link className="btn btn-primary btn-sm me-2" to={`/${item.slug}`}>View</Link>
                                            <Link className='btn btn-warning btn-sm me-2' to={`edit/${item.slug}`} state={{ id: item.id }}>Edit</Link>
                                            <button className="btn btn-danger btn-sm" onClick={() => {setBlogsDeleteId(item.id);setShowModal(true)}}>Delete</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            className="mt-4 custom-pagination"
                        />
                    </>
                    :
                    !loading ? <NoRecordFound title='No Record Found' text="It looks like we haven't received any record yet. If you have recently created a blog, please allow some time for it to be processed." />
                        : <h1 className='title-heading mt-5 text-center'> Loading Data ...... </h1>
                }
            </div>

            {showModal && <ConfirmDeleteModal title="Blogs" handleDelete={deleteBlogsById} hideModal={() => setShowModal(false)} />}
        </>
    )
}

export default Blogs