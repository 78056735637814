import React from 'react'
import { Link, useParams } from 'react-router-dom';
import logo from '../../assets/home/usravens-logo.webp'

function DashboardSideBar() {
  const data = useParams();
  return (
    <>
      <div className="bg-light border-right" id="sidebar-wrapper">
        <div className="sidebar-heading d-flex justify-content-between align-items-center">
          <Link to='/dashboard'>
            <img src={logo} className='w-100' alt='logo' />
          </Link>
        </div>
        <div className="list-group list-group-flush mt-3">
          <Link to="/dashboard" className={`list-group-item list-group-item-action bg-light ${Object.values(data)[0] === "" ? "active" : ""}`}>Dashboard</Link>
          <Link to="blogs" className={`list-group-item list-group-item-action bg-light ${Object.values(data)[0].startsWith("blogs") ? "active" : ""}`}>Blogs</Link>
          <Link to="category" className={`list-group-item list-group-item-action bg-light ${Object.values(data)[0].startsWith("category") ? "active" : ""}`}>Category</Link>
          <Link to="feedback" className={`list-group-item list-group-item-action bg-light ${Object.values(data)[0].startsWith("feedback") ? "active" : ""}`}>Feedback</Link>
          <Link to="author" className={`list-group-item list-group-item-action bg-light ${Object.values(data)[0].startsWith("author") ? "active" : ""}`}>Author</Link>
          <Link to="career" className={`list-group-item list-group-item-action bg-light ${Object.values(data)[0].startsWith("career") ? "active" : ""}`}>Career</Link>
          <Link to="contact-us" className={`list-group-item list-group-item-action bg-light ${Object.values(data)[0].startsWith("contact-us") ? "active" : ""}`}>Contact us</Link>
        </div>
      </div>
    </>
  )
}

export default DashboardSideBar