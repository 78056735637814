import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function View() {
    const { state } = useLocation();;
    return (
        <>
            <div className="container-fluid mt-4">
                <div className="p-4 ">
                    <h1 className="text-center mb-4">Career Form Detail</h1>
                    <div className="row mb-3">
                        <div className="col-md-6 mt-5">
                            <h4 className='title-heading mb-5'>{state.firstName} {state.lastName}</h4>
                            <p className='mb-3'><strong>Email:</strong> {state.email}</p>
                            <p className='mb-3'><strong>Contact:</strong> {state.contact}</p>
                            <p className='mb-3'><strong>Designation:</strong> {state.designation}</p>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-center">
                            <Link to={`${process.env.REACT_APP_BACKEND_BASE_IMAGE_URL}/${state.fileUrl}`} target='_blank' className="btn btn-outline-primary btn-sm">Download Resume</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default View