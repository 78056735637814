import React from 'react'
import { Outlet } from 'react-router-dom'
import CustomHeader from '../componets/CustomHeader'
import CustomFooter from '../componets/CustomFooter'
import SidebarSocialLinks from '../componets/SidebarSocialLinks'

function CommonLayout() {
  return (
    <>
      <CustomHeader />
      <main>
        <SidebarSocialLinks />
        <Outlet />
      </main>
      <CustomFooter />
    </>
  )
}

export default CommonLayout