import React from 'react'

function NoRecordFound({categoryTitle,title,text}) {
    return (
        <div style={{ padding: '20px', textAlign: 'center', marginTop: "100px" }}>
            <h4 className=''>{categoryTitle ?? ''}</h4>
            <h1 className='title-heading text-danger'>{title}</h1>
            <p className='mt-5'>
                {text}
            </p>
        </div>
    )
}

export default NoRecordFound