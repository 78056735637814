import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup";
import { errorMessage, successMessage } from '../../../helpers/Message';
import instance from '../../../services/apiServices/Api';
import SimpleBackdrop from '../../../componets/SimpleBackdrop';
import TextField from '@mui/material/TextField';
import { useLocation, useNavigate } from 'react-router-dom';
import { TagsInput } from 'react-tag-input-component';

function CreateAndEdit() {

    const { state } = useLocation()
    const Id = state?.id
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);

    const formik = useFormik({
        initialValues: {
            slug: "",
            title: "",
            description: "",
            tags: [],
        },
        validationSchema: Yup.object({
            slug: Yup.string().required("Full name is required"),
            title: Yup.string().required("Title is required"),
            // description: Yup.string().required("Summary is required"),
            // tags: Yup.string().required("Content is required"),
        }),

        onSubmit: (values) => {
            const formData = new FormData()
            formData.append("slug", values.slug);
            formData.append("title", values.title);
            formData.append("description", values.description);
            formData.append("tags", JSON.stringify(selectedTags));
            setLoading(true)
            if (Id) {
                instance.put(`/update-category/${state?.id}`, formData)
                    .then((res) => {
                        setLoading(false)
                        successMessage('Success', "Category updated Successfuly").then(() => navigate('/dashboard/category'))
                    })
                    .catch((err) => {
                        setLoading(false);
                        errorMessage("Error", err.response?.message);
                    });
            } else {
                instance.post('/category', formData)
                    .then((res) => {
                        setLoading(false)
                        successMessage('Success', "Category created Successfuly").then(() => navigate('/dashboard/category'))
                    })
                    .catch((err) => {
                        setLoading(false);
                        errorMessage("Error", err.response?.message);
                    });
            }

        },
    });

    const getDataById = (Id) => {
        setLoading(true)
        instance.get(`/category-id/${Id}`)
            .then((res) => {
                setLoading(false);
                const data = res.data.data
                formik.setFieldValue("slug", data.slug);
                formik.setFieldValue("title", data.title);
                formik.setFieldValue("description", data.description);
                setSelectedTags(JSON.parse(data.tags));
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response?.message);
            });
    }

    useEffect(() => {
        if (Id) {
            getDataById(Id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [Id])

    return (
        <>
            {loading && <SimpleBackdrop />}
            <div className="container-fluid mt-4 blogs-form">
                <div className="align-items-center d-flex justify-content-between mb-4">
                    <h1 className='mb-5'>{state?.id ? "Edit Category" : "Create Category"}</h1>
                </div>

                <form
                    onSubmit={formik.handleSubmit}
                    className="auth-outer"
                    action="#"
                >
                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Title"
                                className='input-field'
                                multiline
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                {...formik.getFieldProps("title")}
                            />
                            {formik.errors.title && formik.touched.title ? (
                                <div className="validation-error">
                                    {formik.errors.title}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group col-md-5">
                            <TextField
                                id="outlined-multiline-flexible"
                                label="slug"
                                className='input-field'
                                multiline
                                error={formik.touched.slug && Boolean(formik.errors.slug)}
                                {...formik.getFieldProps("slug")}
                            />
                            {formik.errors.slug && formik.touched.slug ? (
                                <div className="validation-error">
                                    {formik.errors.slug}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-5">
                            <TextField
                                id="outlined-multiline-flexible"
                                label="Description"
                                className='input-field'
                                multiline
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                {...formik.getFieldProps("description")}
                            />
                            {formik.errors.description && formik.touched.description ? (
                                <div className="validation-error">
                                    {formik.errors.description}
                                </div>
                            ) : null}
                        </div>
                        <div className="form-group col-md-5">
                            <TagsInput
                                value={selectedTags}
                                onChange={setSelectedTags}
                                name="tags"
                                separators={["Enter", ","]}
                                placeHolder="Enter Tags"
                                classNames='tags-field'

                            />
                            {formik.errors.tags && formik.touched.tags ? (
                                <div className="validation-error">
                                    {formik.errors.tags}
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="align-items-center d-flex justify-content-center mt-5">
                        <button type="submit" className="primary-btn btn-large submit-btn">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateAndEdit