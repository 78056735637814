import React from 'react';
import DashboardHome from '../../pages/dashboard/DashboardHome';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import profileImg from '../../assets/Dashboard/profile.jpg'
import Blogs from '../../pages/dashboard/blogs/Blogs';
import Feedback from '../../pages/dashboard/feedback/Feedback';
import Career from '../../pages/dashboard/career/Career';
import ContactUs from '../../pages/dashboard/contactus/ContactUs';
import CareerView from '../../pages/dashboard/career/View';
import ContactView from '../../pages/dashboard/contactus/View';
import FeedbackView from '../../pages/dashboard/feedback/View';
import FeedbackCreateAndEditForm from '../../pages/dashboard/feedback/CreateAndEditForm';
import Storage from '../../services/local_storage/localStorage';
import PageNotFound from '../../pages/PageNotFound';
import ProfileUpdate from '../../pages/dashboard/ProfileUpdate';
import CreateAndEditBlogs from '../../pages/dashboard/blogs/CreateAndEditBlogs';
import CategoryList from '../../pages/dashboard/categories/List';
import CreateAndEditCategory from '../../pages/dashboard/categories/CreateAndEdit';
import Author from '../../pages/dashboard/author/Author';
import AuthorView from '../../pages/dashboard/author/View';
import AuthorCreateAndEditForm from '../../pages/dashboard/author/CreateAndEditForm';



function Content() {
    const location = useLocation();
    const navigate = useNavigate()
    const userData = Storage.getUserData();
    const text = location?.pathname?.slice(location?.pathname?.lastIndexOf("/")).substring(1);
    const activeBreadcrumb = text.charAt(0).toUpperCase() + text.slice(1)

    const logout = () => {
        Storage.removeData('token');
        Storage.removeData('userData');
        navigate('/admin-login');
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center justify-content-between mb-4">
                    <div className="col-md-8">
                        <ol className="breadcrumb my-4">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active">{activeBreadcrumb === "Dashboard" ? "" : activeBreadcrumb}</li>
                        </ol>
                    </div>
                    <div className="col-md-1 text-right">
                        <div className="btn-group">
                            <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src={userData.profileImage ?? profileImg} className='profileImg' alt='profile img' />
                            </button>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="settings">Settings</Link></li>
                                <li><button className="dropdown-item" onClick={logout} >Logout</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="content-wrapper bg-white p-4">
                    <Routes>
                        <Route path='/' element={<DashboardHome />} />
                        <Route path='/blogs' element={<Blogs />} />
                        <Route path='/blogs/create' element={<CreateAndEditBlogs />} />
                        <Route path='/blogs/edit/:slug' element={<CreateAndEditBlogs />} />
                        <Route path='/category' element={<CategoryList />} />
                        <Route path='/category/create' element={<CreateAndEditCategory />} />
                        <Route path='/category/edit/:slug' element={<CreateAndEditCategory />} />
                        <Route path='/feedback' element={<Feedback />} />
                        <Route path='/feedback/view' element={<FeedbackView />} />
                        <Route path='/feedback/create' element={<FeedbackCreateAndEditForm />} />
                        <Route path='/feedback/edit/:id' element={<FeedbackCreateAndEditForm />} />
                        <Route path='/career' element={<Career />} />
                        <Route path='/career/view' element={<CareerView />} />
                        <Route path='/contact-us' element={<ContactUs />} />
                        <Route path='/contact-us/view' element={<ContactView />} />
                        <Route path='/settings' element={<ProfileUpdate />} />
                        <Route path='/author' element={<Author />} />
                        <Route path='/author/view' element={<AuthorView />} />
                        <Route path='/author/create' element={<AuthorCreateAndEditForm />} />
                        <Route path='/author/edit/:id' element={<AuthorCreateAndEditForm />} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </div>
            </div>
        </>
    )
}

export default Content