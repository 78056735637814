import React, { useEffect, useState } from "react";
import Logo from "../assets/home/usravens-logo.webp";
import { Link, useNavigate } from "react-router-dom";
import facebookSvg from "../assets/home/facebook-fill.svg";
import instagramSvg from "../assets/home/instagram-fill.svg";
import twitterSvg from "../assets/home/x-fill.svg";
import linkedinSvg from "../assets/home/linkedin-fill.svg";
import youtubeSvg from "../assets/home/youtube-fill.svg";
import { HashLink } from "react-router-hash-link";

function CustomHeader() {
  const menuList = [
    { title: "Home", to: "/" },
    { title: "About", to: "/about-us" },
    { title: "Services", to: "/service" },
    {
      title: "Carrier",
      subMenu: [
        { title: "Become a carrier", to: "/carrier" },
        { title: "Why partner with us?", to: "/why-partner-with-us" },
      ],
    },
    { title: "Blog", to: "/blog" },
    { title: "Contact", to: "/contact" },
  ];
  const navigate = useNavigate();
  const [scrolltopdata, setscrolltopdata] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 18) {
        setscrolltopdata(false);
      } else {
        setscrolltopdata(true);
      }
    });
  }, []);
  return (
    <>
      <section
        className={`header-section mt-4 bg-transparent w-100 overflow-hidden ${
          scrolltopdata ? "scrolled" : ""
        }`}
      >
        <div className="container header-main">
          <div className="header-inner-container d-flex justify-content-between align-items-center">
            <div className="logo ms-5">
              <img
                src={Logo}
                alt="Logo"
                width='200'
                height='54'
                onClick={() => navigate("/")}
              />
            </div>
            <div className="navbar">
              <ul className=" d-flex list-unstyled align-items-center m-0 me-4">
                <li className="me-5">
                  <Link to={"/shipper/make-payment"} className="d-none">Make Payment</Link>
                </li>
                <li className="me-5">
                  <HashLink smooth to="/#tracking-section">
                    Track a Shipment
                  </HashLink>
                </li>
              </ul>
              <div
                className="burger-menu me-5 position-relative"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasResponsive"
                aria-controls="offcanvasResponsive"
              >
                <div className="menu">
                  <div className="bar"></div>
                  <div className="bar"></div>
                  <div className="bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="offcanvas offcanvas-end w-100 "
          tabIndex="-1"
          id="offcanvasResponsive"
          aria-labelledby="offcanvasResponsiveLabel"
        >
          <div className="offcanvas-header">
            <div className="container header-main d-flex justify-content-between align-items-center">
              <div className="logo">
                <img src={Logo} alt="Logo" width='202.5' height='52.5' />
              </div>
              <div className="navbar">
                <ul className=" d-flex list-unstyled align-items-center m-0">
                  <li className="me-5">
                    <Link to={"https://usravens.taicloud.net/Main/Home"} target="_blank" className="fs-6">
                      Agent Potal
                    </Link>
                  </li>
                </ul>
                <button
                  type="button"
                  className="close-btn"
                  data-bs-dismiss="offcanvas"
                  data-bs-target="#offcanvasResponsive"
                  aria-label="Close"
                >
                  close
                </button>
              </div>
            </div>
          </div>
          <div className="offcanvas-body" style={{ zIndex: 99 }}>
            <div className="container d-flex justify-content-between">
              <div className="right-side-menu col-6">
                <ul className="side-menu-list m-0 p-0">
                  {menuList.map((item, index) =>
                    item.subMenu ? (
                      <li className="title-heading" key={index}>
                        {item.title}
                        <ul className="carrier-menu-sub-list my-3 mx-4">
                          {item.subMenu.map((item, index) => (
                            <li
                              key={index}
                              data-bs-dismiss="offcanvas"
                              data-bs-target="#offcanvasResponsive"
                              aria-label="Close"
                            >
                              <Link to={item.to} className="title-heading">
                                {item.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ) : (
                      <li
                        key={index}
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#offcanvasResponsive"
                        aria-label="Close"
                      >
                        <Link to={item.to} className="title-heading">
                          {item.title}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>
              <div className="side-center-buttons row d-none">
                <Link
                  to={"https://usravens.taicloud.net/Main/Home"}
                  target="_blank"
                  className="col-4"
                >
                  Agent Potal
                </Link>
                <Link to={"/shipper/make-payment"} className="col-4 d-none">
                  Make Payment
                </Link>
              </div>
              <div className="left-side-text align-content-end">
                <div className="left-side-bottom-text">
                  <p>8 The Green, Suite B,<br/>Dover, Delaware 19901</p>
                  <p>+1 302-401-4033<br/>Mon to Fri 9am to 6pm</p>
                  <p>info@usravens.com<br/>Send us your query anytime!</p>
                  <div className="footer-social-links">
                    <ul className="social-links-list d-flex m-0 p-0">
                      <li>
                        <Link
                          to="https://www.facebook.com/usravens"
                          target="_blank"
                        >
                          <img src={facebookSvg} alt="facebook" width='16' height='16' />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/usravens/"
                          target="_blank"
                        >
                          <img src={instagramSvg} alt="instagram" width='16' height='16' />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://twitter.com/USRavens1"
                          target="_blank"
                        >
                          <img src={twitterSvg} alt="twitter" width='16' height='16' />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.linkedin.com/company/usravens/"
                          target="_blank"
                        >
                          <img src={linkedinSvg} alt="linkedin svg" width='16' height='16' />
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.youtube.com/channel/UC_-4o4vJCnh8f1cTnT5v-zw"
                          target="_blank"
                        >
                          <img src={youtubeSvg} alt="youtube" width='16' height='16' />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomHeader;
