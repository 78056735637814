import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { errorMessage, successMessage } from "../../../helpers/Message";
import instance from "../../../services/apiServices/Api";
import SimpleBackdrop from "../../../componets/SimpleBackdrop";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import placeholderImage from "../../../assets/home/Placeholder-image.webp";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import RichTextEditor from "../../../componets/DraftEditor";

function CreateAndEditBlogs() {
  const { slug } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoriesList, setCategoriesList] = useState([]);
  const [authorsList, setAuthorsList] = useState([]);
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
    formik.setFieldValue("imageFileName", e.target.files[0].name);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };
  const handleContent = (htmlString) => {
    formik.setFieldValue("content", htmlString);
  };

  const formik = useFormik({
    initialValues: {
      slug: "",
      title: "",
      summary: "",
      content: "",
      author: "",
      category: "",
      image: null,
      imageFileName: "",
      tags: [],
    },
    validationSchema: Yup.object({
      slug: Yup.string().required("Slug is required"),
      title: Yup.string().required("Title is required"),
      summary: Yup.string().required("Summary is required"),
      content: Yup.string().required("Content is required"),
      author: Yup.string().required("Author is required"),
      category: Yup.string().required("Category is required"),
      imageFileName: Yup.string().required("Image is required"),
    }),

    onSubmit: async (values) => {
      const slugValue = await values.slug.toLowerCase().replace(/ /g, "-");
      const formData = new FormData();
      formData.append("slug", slugValue);
      formData.append("title", values.title);
      formData.append("summary", values.summary);
      formData.append("content", values.content);
      formData.append("category", values.category);
      formData.append("author", values.author);
      formData.append("image", image);
      formData.append("tags", JSON.stringify(selectedTags));
      setLoading(true);
      if (state?.id) {
        instance
          .put(`/update-blog/${state?.id}`, formData)
          .then((res) => {
            setLoading(false);
            successMessage("Success", "Blog updated Successfuly").then(() =>
              navigate("/dashboard/blogs")
            );
          })
          .catch((err) => {
            setLoading(false);
            errorMessage("Error", err.response?.message);
          });
      } else {
        instance
          .post("/blog", formData)
          .then((res) => {
            setLoading(false);
            successMessage("Success", "Blog created Successfuly").then(() =>
              navigate("/dashboard/blogs")
            );
          })
          .catch((err) => {
            setLoading(false);
            errorMessage("Error", err.response?.message);
          });
      }
    },
  });

  const getDataById = (slug) => {
    setLoading(true);
    instance
      .get(`/blog/${slug}`)
      .then((res) => {
        setLoading(false);
        const data = res.data.data;
        formik.setFieldValue("slug", data.slug);
        formik.setFieldValue("title", data.title);
        formik.setFieldValue("summary", data.summary);
        formik.setFieldValue("author", data.author);
        formik.setFieldValue("category", data.category);
        formik.setFieldValue("image", data.image);
        formik.setFieldValue("imageFileName", data.image.split("/")[2]);
        formik.setFieldValue("content", data.content);
        setPreviewImage(
          process.env.REACT_APP_BACKEND_BASE_IMAGE_URL + data.image
        );
        setSelectedTags(JSON.parse(data.tags));
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
        errorMessage("Error1", err.response?.message);
      });
  };
  const getCategoryList = () => {
    setLoading(true);
    instance
      .get(`/category`, {
        params: {
          page: 1,
          limit: 100,
        },
      })
      .then((res) => {
        setLoading(false);
        setCategoriesList(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response?.message);
      });
  };
  const getAuthorList = () => {
    setLoading(true);
    instance
      .get(`/author`, {
        params: {
          page: 1,
          limit: 100,
        },
      })
      .then((res) => {
        setLoading(false);
        setAuthorsList(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        errorMessage("Error", err.response?.message);
      });
  };
  useEffect(() => {
    if (slug) {
      getDataById(slug);
    }
    getCategoryList();
    getAuthorList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  const handleCategoryChange = async (e) => {
    formik.setFieldValue("category", e.target.value);
    const tagsdata = await categoriesList.find(
      (item) => item.id === e.target.value
    );
    if (tagsdata.tags !== null) {
      setSelectedTags(JSON.parse(tagsdata.tags));
    }
  };

  return (
    <>
      {loading && <SimpleBackdrop />}
      <div className="container-fluid mt-4 blogs-form">
        <div className="align-items-center d-flex justify-content-between mb-4">
          <h1 className="mb-5">{state?.id ? "Edit Blog" : "Create Blog"}</h1>
        </div>

        <form onSubmit={formik.handleSubmit} className="auth-outer" action="#">
          <div className="form-row">
            <div className="form-group col-md-5">
              <TextField
                id="outlined-multiline-flexible"
                label="Title"
                className="input-field"
                multiline
                error={formik.touched.title && Boolean(formik.errors.title)}
                {...formik.getFieldProps("title")}
              />
              {formik.errors.title && formik.touched.title ? (
                <div className="validation-error">{formik.errors.title}</div>
              ) : null}
            </div>
            <div className="form-group col-md-5">
              <TextField
                id="outlined-multiline-flexible"
                label="Blog slug"
                className="input-field"
                multiline
                error={formik.touched.slug && Boolean(formik.errors.slug)}
                {...formik.getFieldProps("slug")}
              />
              {formik.errors.slug && formik.touched.slug ? (
                <div className="validation-error">{formik.errors.slug}</div>
              ) : null}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5">
              <TextField
                id="outlined-multiline-flexible"
                label="Summary"
                className="input-field"
                multiline
                error={formik.touched.summary && Boolean(formik.errors.summary)}
                {...formik.getFieldProps("summary")}
              />
              {formik.errors.summary && formik.touched.summary ? (
                <div className="validation-error">{formik.errors.summary}</div>
              ) : null}
            </div>
            <div className="form-group col-md-5">
              <FormControl fullWidth>
                <InputLabel>Author</InputLabel>
                <Select
                  label="Author"
                  value={formik.getFieldProps("author").value}
                  className={`input-field ${
                    Boolean(formik.errors.author) ? "Mui-error" : ""
                  }`}
                  onChange={(e) =>
                    formik.setFieldValue("author", e.target.value)
                  }
                  error={formik.touched.author && Boolean(formik.errors.author)}
                >
                  {authorsList.length > 0 &&
                    authorsList.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.fullName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formik.errors.author && formik.touched.author ? (
                <div className="validation-error">{formik.errors.author}</div>
              ) : null}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5">
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  value={formik.getFieldProps("category").value}
                  className={`input-field ${
                    Boolean(formik.errors.category) ? "Mui-error" : ""
                  }`}
                  onChange={handleCategoryChange}
                  error={
                    formik.touched.category && Boolean(formik.errors.category)
                  }
                  // {...formik.getFieldProps("category")}
                >
                  {categoriesList.length > 0 &&
                    categoriesList.map((item, index) => (
                      <MenuItem value={item.id} key={index}>
                        {item.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {formik.errors.category && formik.touched.category ? (
                <div className="validation-error">{formik.errors.category}</div>
              ) : null}
            </div>
            <div className="form-group col-md-5">
              <TagsInput
                value={selectedTags}
                onChange={(tags) => {
                  const modifiedTags = tags.map((tag) =>
                    tag.trim().replace(/\s+/g, "-")
                  );
                  setSelectedTags(modifiedTags);
                }}
                name="tags"
                separators={["Enter", ","]}
                placeHolder="Enter Tags"
                classNames="tags-field"
              />
              {formik.errors.tags && formik.touched.tags ? (
                <div className="validation-error">{formik.errors.tags}</div>
              ) : null}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5 ">
              <div className="d-flex justify-content-between">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Upload Image"
                  className="input-file-field"
                  multiline
                  error={
                    formik.touched.imageFileName &&
                    Boolean(formik.errors.imageFileName)
                  }
                  disabled
                  {...formik.getFieldProps("imageFileName")}
                />
                <Button
                  variant="contained"
                  component="label"
                  className="file-button"
                >
                  {"Choose File"}
                  <input
                    type="file"
                    hidden
                    id="resume"
                    name="resume"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
              {formik.errors.imageFileName && formik.touched.imageFileName ? (
                <div className="validation-error">
                  {formik.errors.imageFileName}
                </div>
              ) : null}
            </div>
            <div className="form-group col-md-5 d-flex justify-content-between">
              <img
                src={previewImage ? previewImage : placeholderImage}
                alt="blogs-profile"
                className="preview-img"
              />
            </div>
          </div>
          <div className="form-row">
            {/* <BlogEditor data={formik.getFieldProps('content').value} handleContent={handleContent} /> */}
            <RichTextEditor
              data={formik.getFieldProps("content").value}
              handleContent={handleContent}
            />
          </div>
          <div className="align-items-center d-flex justify-content-center mt-5">
            <button type="submit" className="primary-btn btn-large submit-btn">
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateAndEditBlogs;
