import React from "react";
import { Link } from "react-router-dom";
import facebookSvg from "../assets/home/facebook-fill.svg";
import instagramSvg from "../assets/home/instagram-fill.svg";
import twitterSvg from "../assets/home/x-fill.svg";
import linkedinSvg from "../assets/home/linkedin-fill.svg";
import youtubeSvg from "../assets/home/youtube-fill.svg";
import arrowRightIcon from "../assets/home/arrow_right.svg";
import logo from "../assets/home/logo_footer.webp"

function CustomFooter() {
  const currentYear = new Date().getFullYear();
  return (
    <section className="footer-section fw-light">
      <div className="container">
        <div className="footer-bar align-items-center bg-light d-flex justify-content-around rounded-3 mb-5">
          <div className="footer-logo col-2">
            <Link to={"/"}>
              <img
                src={logo}
                alt="logo"
                loading="lazy"
                width="150px"
                height="90px"
              />
            </Link>
          </div>
          <div className="footer-contact">
            <Link
              className="footer-mail d-flex align-items-center mb-1"
              to="mailto:info@usravens.com"
            >
              <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16"
                height="16"
              >
                <title>mail-open-fill-svg</title>
                <defs>
                  <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
                    <path d="m0 0h16v16h-16z" />
                  </clipPath>
                </defs>
                <g id="Clip-Path" clipPath="url(#cp1)">
                  <g id="Layer">
                    <path
                      id="Layer"
                      fillRule="evenodd"
                      className="s0"
                      d="m1.5 4.6l6.2-3.7q0.1-0.1 0.3-0.1 0.2 0 0.3 0.1l6.2 3.7q0.1 0 0.1 0.1 0.1 0.1 0.1 0.2v8.4q0 0.3-0.2 0.5-0.2 0.2-0.5 0.2h-12q-0.3 0-0.5-0.2-0.2-0.2-0.2-0.5v-8.4q0-0.1 0.1-0.2 0-0.1 0.1-0.1zm10.7 0.9l-4.2 3.6-4.2-3.6-0.9 1 5.1 4.4 5.1-4.4z"
                    />
                  </g>
                </g>
              </svg>
              <p className="m-0 ms-2">info@usravens.com</p>
            </Link>
            <Link
              className="footer-phone d-flex align-items-center mb-1"
              to="tel:1203024014033"
            >
              <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16"
                height="16"
              >
                <title>phone-fill-svg</title>
                <defs>
                  <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
                    <path d="m0 0h16v16h-16z" />
                  </clipPath>
                </defs>
                <g id="Clip-Path" clipPath="url(#cp1)">
                  <g id="Layer">
                    <path
                      id="Layer"
                      className="s0"
                      d="m14 10.9v2.4q0 0.3-0.2 0.5-0.2 0.1-0.4 0.2-0.5 0-0.7 0c-5.9 0-10.7-4.8-10.7-10.7q0-0.2 0-0.7 0.1-0.2 0.2-0.4 0.2-0.2 0.5-0.2h2.4q0.1 0 0.2 0.1 0.1 0.1 0.1 0.2 0 0.2 0 0.4 0.2 1.4 0.8 2.6c0.1 0.2 0.1 0.3-0.1 0.4l-1.4 1c0.9 2.1 2.5 3.7 4.6 4.6l1-1.4q0.1-0.1 0.2-0.2 0.1 0 0.2 0.1 1.2 0.6 2.6 0.8 0.2 0 0.4 0 0.1 0 0.2 0.1 0.1 0.1 0.1 0.2z"
                    />
                  </g>
                </g>
              </svg>
              <p className="m-0 ms-2">+1 302-401-4033</p>
            </Link>
            <Link
              className="footer-fax d-flex align-items-center"
              to="fax:3022008096"
            >
              <svg
                version="1.2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width="16"
                height="16"
              >
                <title>printer-fill-svg</title>
                <defs>
                  <clipPath clipPathUnits="userSpaceOnUse" id="cp1">
                    <path d="m0 0h16v16h-16z" />
                  </clipPath>
                </defs>
                <g id="Clip-Path" clipPath="url(#cp1)">
                  <g id="Layer">
                    <path
                      id="Layer"
                      fillRule="evenodd"
                      className="s0"
                      d="m4.7 11.3h6.6v3.4h-6.6zm8 2v-3.4h-9.4v3.4h-1.3q-0.3 0-0.5-0.2-0.2-0.2-0.2-0.5v-6.7q0-0.2 0.2-0.4 0.2-0.2 0.5-0.2h12q0.3 0 0.5 0.2 0.2 0.2 0.2 0.4v6.7q0 0.3-0.2 0.5-0.2 0.2-0.5 0.2zm-9.3-6.7v1.3h2v-1.3zm1.3-5.3h6.7q0.2 0 0.4 0.2 0.2 0.2 0.2 0.4v2h-8v-2q0-0.2 0.2-0.4 0.2-0.2 0.5-0.2z"
                    />
                  </g>
                </g>
              </svg>
              <p className="m-0 ms-2">+1 302-200-8096</p>
            </Link>
          </div>
          <div className="footer-social-links align-items-center d-flex justify-content-between">
            <p className="m-0"> Follow Us On:</p>
            <ul className="social-links-list d-flex">
              <li>
                <Link to="https://www.facebook.com/usravens" target="_blank">
                  <img src={facebookSvg} alt="facebook" width='14px' height='14px' />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/usravens/" target="_blank">
                  <img src={instagramSvg} alt="instagram" width='14px' height='14px' />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/USRavens1" target="_blank">
                  <img src={twitterSvg} alt="twitter" width='14px' height='14px' />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.linkedin.com/company/usravens/"
                  target="_blank"
                >
                  <img src={linkedinSvg} alt="linkedin" width='14px' height='14px' />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.youtube.com/channel/UC_-4o4vJCnh8f1cTnT5v-zw"
                  target="_blank"
                >
                  <img src={youtubeSvg} alt="youtube" width='14px' height='14px' />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom row justify-content-between text-light mt-8 mb-5">
          <div className="footer-bottom-text col-md-3">
            <p className="fw-bold">
              US Ravens is One-Stop Solution for Your Logistic Needs
            </p>
            <p>
              Through our integrated network, we arrange the perfect transport
              for your shipments, regardless of their size or requirements. We
              work with you to develop specialized freight moving plans that
              handle your greatest supply chain difficulties, irrespective of
              your company’s demands and seasonality or the complexities of your
              sector.
            </p>
          </div>
          <div className="footer-usefull col-md-2">
            <h3 className="list-heading">Usefull Links</h3>
            <ul className="footer-useful-links m-0 p-0 mt-3">
              <li>
                <Link to={"/"} className="text-light d-flex align-items-center">
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to={"/service"}
                  className="text-light d-flex align-items-center"
                >
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to={"/about-us"}
                  className="text-light d-flex align-items-center"
                >
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Who we are
                </Link>
              </li>
              <li>
                <Link
                  to={"/blog"}
                  className="text-light d-flex align-items-center"
                >
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Blog
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-career col-md-2">
            <h3 className="list-heading">Career</h3>
            <ul className="footer-career-links m-0 p-0">
              <li>
                <Link
                  to={"/carrier"}
                  className="text-light d-flex align-items-center"
                >
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Become a carrier
                </Link>
              </li>
              <li>
                <Link
                  to={"/why-partner-with-us"}
                  className="text-light d-flex align-items-center"
                >
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Why partner with us?
                </Link>
              </li>
              <li>
                <Link
                  to={"/careers"}
                  className="text-light d-flex align-items-center"
                >
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Jobs
                </Link>
              </li>
              <li>
                <Link
                  to={"/contact"}
                  className="text-light d-flex align-items-center"
                >
                  <img src={arrowRightIcon} alt="arrow icon" width='24px' height='24px' />
                  Contact us
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-newsletter col-md-4">
            <h3 className="list-heading">Join Our Newsletter</h3>
            <p>
              Catch the latest updates, exclusive offers, and industry insights
              – subscribe to our email newsletter today!
            </p>
            <form action="" className="d-flex">
              <input
                type="email"
                name="newsletterMail"
                id=""
                placeholder="Your Email Id"
              />
              <button type="button" className="">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="footer-copyright-section text-light">
        <div className=" container text-center">
          <p className="m-0">
            Copyright © {currentYear} US Ravens Logistics | Powered by{" "}
            <Link to={"https://knotsync.com/"} target="_blank">
              <b>Knotsync</b>
            </Link>
          </p>
        </div>
      </div>
    </section>
  );
}

export default CustomFooter;
