import React from 'react'

function ConfirmDeleteModal({title,handleDelete,hideModal}) {
    return (
        <div className="modal show fade" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirm Delete</h5>
                    </div>
                    <div className="modal-body">
                        <p>Are you sure you want to delete this {title}?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => hideModal(false)}>Cancel</button>
                        <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmDeleteModal