import React from 'react'
import DashboardSideBar from '../componets/dashboard/DashboardSideBar'
import Content from '../componets/dashboard/Content'

function DashboardLayout() {
    return (
        <>
            {/* <CustomHeader /> */}
            <div className="d-flex" id="wrapper">
                <DashboardSideBar />
                <div id="page-content-wrapper">
                    <Content />
                </div>
            </div>
            {/* <CustomFooter /> */}
        </>
    )
}

export default DashboardLayout