import React, { useEffect, useState } from 'react'
import SimpleBackdrop from '../../../componets/SimpleBackdrop';
import instance from '../../../services/apiServices/Api';
import { errorMessage, successMessage } from '../../../helpers/Message';
import NoRecordFound from '../../../componets/NoRecordFound';
import ConfirmDeleteModal from '../../../componets/ConfirmDeleteModal';
import { Link } from 'react-router-dom';
import profileImg from '../../../assets/Dashboard/profile.jpg'

function Feedback() {
    const [feedbackData, setFeedbackData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [deleteID, setDeleteId] = useState(null);
    const [imageLoaded, setImageLoaded] = useState(false)

    const deleteById = () => {
        if (deleteID) {
            setLoading(true);
            instance.delete(`/delete-feedback/${deleteID}`)
                .then((res) => {
                    setLoading(false);
                    setShowModal(false);
                    getFeedbackList();
                    successMessage(
                        "Success!",
                        "Feedback Deleted Successfuly"
                    );
                })
                .catch((err) => {
                    setLoading(false);
                    errorMessage("Error", err.response.data.error);
                });
        }
    }

    const getFeedbackList = () => {
        setLoading(true)
        instance.get(`/feedback`)
            .then((res) => {
                setLoading(false);
                setFeedbackData(res.data.data);
            })
            .catch((err) => {
                setLoading(false);
                errorMessage("Error", err.response.data.message);
            });
    }

    useEffect(() => {
        getFeedbackList();
    }, [])

    const handleImageLoad = (id) => {
        setImageLoaded((prevState) => ({ ...prevState, [id]: true }));
    };
    return (
        <>
            {loading && <SimpleBackdrop />}
            <div className="mt-4 container-fluid">
                <div className="align-items-center d-flex justify-content-between mb-4">
                    <h1 className="">Our Valueable Feedback List</h1>
                    <Link className='btn btn-primary' to={'create'}>Create</Link>
                </div>
                {feedbackData.length > 0 ?
                    <table className="table table-striped mt-5">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Full Name</th>
                                <th>Review</th>
                                <th>Image</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feedbackData.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.fullName}</td>
                                    <td>{item.feedbackText}</td>
                                    <td>
                                        <img
                                            src={item.image}
                                            onLoad={() => handleImageLoad(item.id)}
                                            className='profileImg w-100'
                                            alt='profile'
                                            style={{ display: imageLoaded[item.id] ? 'block' : 'none' }}
                                        />
                                        {!imageLoaded[item.id] && (
                                            <img
                                                src={profileImg}
                                                className='profileImg w-100'
                                                alt='profile'
                                            />
                                        )}
                                    </td>
                                    <td>
                                        <Link className="btn btn-primary btn-sm me-2" to='view' state={item}>View</Link>
                                        <Link className='btn btn-warning btn-sm me-2' to={`edit/${item.id}`}>Edit</Link>
                                        <button className="btn btn-danger btn-sm" onClick={() => {setDeleteId(item.id);setShowModal(true)}}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    :
                    !loading ? <NoRecordFound title='No Submissions Yet' text="It looks like we haven't received any form submissions yet. If you have recently submitted a form, please allow some time for it to be processed." />
                        : <h1 className='title-heading mt-5 text-center'> Loading Data ...... </h1>
                }
            </div>
            {showModal && <ConfirmDeleteModal title="Feedback Data" handleDelete={deleteById} hideModal={() => setShowModal(false)} />}
        </>
    )
}

export default Feedback